import { graphql } from 'gatsby'
import React from 'react'
import Layout from 'components/shared/Layout'
import SEO from 'components/shared/SEO'
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader'
import DefaultMDXRenderer from 'components/shared/DefaultMDXRenderer'
deckDeckGoHighlightElement()

const WikiTemplate = ({ location, data }) => {
  const {
    mdx: post,
    site: { siteMetadata },
  } = data

  return (
    <>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        article
      />

      <Layout location={location}>
        <div className="wrapper">
          <header className="pb-12 pt-8 md:pt-16">
            <div className="mb-1 md:mb-2">
              <span className="bg-primary bg-opacity-50 px-1.5 text-sm md:text-base">
                {post.frontmatter.category}
              </span>
            </div>
            <h1 className="text-3xl md:text-4xl font-bold">
              {post.frontmatter.title}
            </h1>
            {post.frontmatter.description && (
              <p className="text-gray-500 mt-2 text-sm md:text-base">
                {post.frontmatter.description}
              </p>
            )}
            <div className="text-gray-500 flex items-center text-sm mt-4">
              <img
                className="rounded-full w-6 h-6 mr-3"
                src={siteMetadata.profileUrl}
                alt={siteMetadata.author}
              />
              <div>
                {siteMetadata.author}
                <span className="mx-1.5">·</span>
                생성일 {post.frontmatter.createdAt}
                <span className="mx-1.5">·</span>
                마지막 수정일 {post.frontmatter.updatedAt}
              </div>
            </div>
          </header>

          <div>
            <DefaultMDXRenderer type="wiki">{post.body}</DefaultMDXRenderer>
          </div>
        </div>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query WikiByPath($path: String!) {
    site {
      siteMetadata {
        siteUrl
        author
        thumbnailUrl
        profileUrl
      }
    }

    mdx(frontmatter: { path: { eq: $path } }) {
      body
      frontmatter {
        createdAt(formatString: "YYYY년 MM월 DD일 hh:mm")
        updatedAt(formatString: "YYYY년 MM월 DD일 hh:mm")
        path
        title
        category
        description
      }
    }
  }
`

export default WikiTemplate
